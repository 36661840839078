import React from 'react'
import type { Widget } from '@danone-global/internal/react/core'

export default {

  tags: ['frequency-selector', 'frequency-selector-v2'],

  Component: React.lazy(() => import('./frequency-selector.widget'))

} as Widget
