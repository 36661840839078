import coconLightWoff from '@danone-global/fonts/cocon-light-webfont.woff'
import coconLightItalicWoff from '@danone-global/fonts/cocon-lightitalic-webfont.woff'
import coconRegularWoff from '@danone-global/fonts/cocon-regular-webfont.woff'
import milupaHandBoldWoff from '@danone-global/fonts/milupahand2018-bold-webfont.woff'
import milupaHandBoldWoff2 from '@danone-global/fonts/milupahand2018-bold-webfont.woff2'
import milupaHandExtraBoldWoff from '@danone-global/fonts/milupahand2018-extrabold-webfont.woff'
import milupaHandExtraBoldWoff2 from '@danone-global/fonts/milupahand2018-extrabold-webfont.woff2'
import milupaHandHeavyWoff from '@danone-global/fonts/milupahand2018-heavy-webfont.woff'
import milupaHandHeavyWoff2 from '@danone-global/fonts/milupahand2018-heavy-webfont.woff2'
import milupaHandItalicWoff from '@danone-global/fonts/milupahand2018-italic-webfont.woff'
import milupaHandItalicWoff2 from '@danone-global/fonts/milupahand2018-italic-webfont.woff2'
import milupaHandRegularWoff from '@danone-global/fonts/milupahand2018-regular-webfont.woff'
import milupaHandRegularWoff2 from '@danone-global/fonts/milupahand2018-regular-webfont.woff2'
import { Font } from '@danone-global/internal/react/core'

export const fonts: Font[] = [
  {
    fontFamily: 'dach-milupa',
    fontWeight: 300,
    fontStyle: 'normal',
    woff: coconLightWoff,
  },
  {
    fontFamily: 'dach-milupa',
    fontWeight: 300,
    fontStyle: 'italic',
    woff: coconLightItalicWoff,
  },
  {
    fontFamily: 'dach-milupa',
    fontWeight: 400,
    fontStyle: 'normal',
    woff: coconRegularWoff,
  },
  {
    fontFamily: 'milupa-hand',
    fontWeight: 400,
    fontStyle: 'normal',
    woff: milupaHandRegularWoff,
    woff2: milupaHandRegularWoff2,
  },
  {
    fontFamily: 'milupa-hand',
    fontWeight: 400,
    fontStyle: 'italic',
    woff: milupaHandItalicWoff,
    woff2: milupaHandItalicWoff2,
  },
  {
    fontFamily: 'milupa-hand',
    fontWeight: 700,
    fontStyle: 'normal',
    woff: milupaHandBoldWoff,
    woff2: milupaHandBoldWoff2,
  },
  {
    fontFamily: 'milupa-hand',
    fontWeight: 800,
    fontStyle: 'normal',
    woff: milupaHandExtraBoldWoff,
    woff2: milupaHandExtraBoldWoff2,
  },
  {
    fontFamily: 'milupa-hand',
    fontWeight: 900,
    fontStyle: 'normal',
    woff: milupaHandHeavyWoff,
    woff2: milupaHandHeavyWoff2,
  },
]
