import { createTheme, Theme } from '@danone-global/internal/react/core'

import { fonts } from './theme.fonts'

export const fontFamily = 'milupa-hand'
export const secondaryFontFamily = 'dach-milupa, sans-serif'

const colors = {
  successLight: '#F8FAF3',
  success: '#66B800',
  warningLight: '#FFF0E5',
  warning: '#FF7100',
  milupaRed: '#c94946',
  milupaGreen: '#0A5F5B',
}

const theme: Theme = createTheme({
  direction: 'ltr',

  unit: 'px',

  typography: {
    fontFamily,
    fonts,
    fontSize: 16,
    fontWeightBold: 800,
  },

  palette: {
    text: {
      primary: '#4c4c4c',
      secondary: '#fff',
      disabled: '#8C8C8C',
      heading: '#4c4c4c',
    },

    primary: {
      light: '#fcf4f4',
      main: colors.milupaRed,
      dark: colors.milupaGreen,
    },

    secondary: {
      light: '',
      main: colors.milupaRed,
      dark: colors.milupaRed,
    },

    background: {
      secondary: colors.milupaGreen,
    },

    success: {
      light: colors.successLight,
      main: colors.success,
      dark: '',
    },

    warning: {
      light: colors.warningLight,
      main: colors.warning,
      dark: '',
    },
  },

  breakpoints: {
    values: {
      md: 816,
      lg: 976,
    },
  },
})

theme.overrides = {
  Button: {
    root: {
      borderRadius: 7.2,
      fontFamily: secondaryFontFamily,
      fontWeight: 600,
    },

    small: {
      fontSize: 12,
    },

    default: {
      borderWidth: 2,
    },

    reversed: {
      borderColor: theme.palette.primary.dark,
      color: theme.palette.primary.dark,
      fill: theme.palette.primary.dark,

      '&:hover': {
        background: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
        color: theme.palette.text.secondary,
        fill: theme.palette.primary.dark,
      },
    },
  },

  Typography: {
    root: {
      textTransform: 'uppercase',
    },

    h1: {
      color: theme.palette.primary.dark,
      fontSize: 30,
      fontWeight: 'bold',
      lineHeight: '36px',

      [theme.breakpoints.up('sm')]: {
        fontSize: 40,
        lineHeight: '46px',
      },
    },

    h2: {
      color: theme.palette.primary.dark,
      fontSize: 24,
      lineHeight: '30px',
      fontWeight: 800,

      [theme.breakpoints.up('sm')]: {
        fontSize: 32,
        lineHeight: '37px',
      },
    },

    h3: {
      color: theme.palette.primary.dark,
      fontWeight: 'bold',
      fontSize: 20,
      lineHeight: '25px',

      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
        lineHeight: '28px',
      },
    },

    h4: {
      color: theme.palette.primary.dark,
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: '23px',

      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
        lineHeight: '24px',
      },
    },

    h5: {
      color: theme.palette.primary.dark,
      fontWeight: 800,
      fontSize: 16,
      lineHeight: '20px',

      [theme.breakpoints.up('sm')]: {
        fontSize: 18,
      },
    },

    body: {
      fontWeight: 'bold',
    },
  },

  Price: {
    root: {
      fontFamily: secondaryFontFamily,
    },
  },

  QuickView: {
    addedIcon: {
      backgroundColor: theme.palette.primary.dark,
    },

    closeIcon: {
      color: theme.palette.primary.dark,
      fill: theme.palette.primary.dark,
    },

    title: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.dark,
    },
  },

  LineItem: {
    root: {
      '& $viewItemLink, $amountContainer p': {
        fontFamily: secondaryFontFamily,
        fontSize: 16,
        fontWeight: theme.typography.fontWeightLight,
        textTransform: 'none',
      },
    },

    label: {
      '& p': {
        fontFamily: secondaryFontFamily,
        fontSize: 12,
        fontWeight: theme.typography.fontWeightLight,
        textTransform: 'none',
      },
    },

    labelInscription: {
      backgroundColor: '#DC8961',
    },

    checkout: {
      '& $nameContainer': {
        justifyContent: 'center',
      },

      '& $subContainer': {
        marginTop: theme.utils.createStyle(0, '!important'),
        opacity: 0.9,
      },
    },

    free: {
      textTransform: 'none',
      fontFamily: secondaryFontFamily,
      fontWeight: theme.typography.fontWeightLight,
    },
  },

  LineItemInscriptions: {
    inscriptionInputsTitle: {
      fontFamily: secondaryFontFamily,
      fontSize: 14,
      fontWeight: theme.typography.fontWeightLight,
      textTransform: 'none',
    },
    inscriptionInputs: {
      '& $inscriptionError p': {
        color: '#D93C3F',
      },
    },
    inscriptionsSummaryTitle: {
      fontSize: 15,
    },
    inscriptionsSummaryItem: {
      fontFamily: secondaryFontFamily,
      fontSize: 14,
      fontWeight: theme.typography.fontWeightLight,
      textTransform: 'none',
    },
  },

  FieldsStepper: {
    control: {
      borderRadius: 50,
      backgroundColor: theme.palette.common.white,
      fill: theme.palette.primary.dark,
      border: theme.utils.createStyle(
        theme.palette.primary.dark,
        '2px',
        'solid',
      ),
    },

    label: {
      fontFamily: secondaryFontFamily,
      fontWeight: theme.typography.fontWeightLight,
    },
  },

  SubscriptionSelector: {
    info: {
      fontFamily: secondaryFontFamily,
      fontSize: 16,
      fontWeight: theme.typography.fontWeightLight,
      textTransform: 'none',
    },

    optionContainer: {
      '& p': {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.primary.dark,
      },
    },

    option: {
      border: theme.utils.createStyle(
        theme.utils.getSize(1, { forceUnit: true }),
        'solid',
        theme.palette.primary.dark,
      ),

      '&:hover': {
        backgroundColor: theme.utils.hexToRgba(
          theme.palette.primary.dark,
          0.15,
        ),
      },
    },

    active: {
      backgroundColor: theme.utils.hexToRgba(theme.palette.primary.dark, 0.1),
    },
  },

  CartContent: {
    faq: {
      '& p': {
        fontFamily: secondaryFontFamily,
        fontWeight: theme.typography.fontWeightLight,
        textTransform: 'none',
        fontSize: 15,

        '& a': {
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
  },

  MyOrder: {
    root: {
      '& h4, p, span': {
        color: theme.palette.common.white,
      },

      '& h4, button > span': {
        fontWeight: theme.typography.fontWeightBold,
      },

      '& p, span': {
        textTransform: 'none',
        fontFamily: secondaryFontFamily,
        fontWeight: theme.typography.fontWeightLight,
      },

      '& .free-shipping': {
        color: colors.successLight,
      },
    },
  },

  RelatedProducts: {
    title: {
      color: theme.palette.primary.dark,
    },
  },

  RelatedProduct: {
    root: {
      marginTop: theme.spacing(),
    },

    subContainer: {
      '& button': {
        padding: theme.spacing(1, 2),
      },
    },
  },

  AddDiscountCode: {
    addButton: {
      borderColor: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius(2),
      padding: theme.spacing(0.75, 1.5),
    },
  },

  DiscountCodePrice: {
    unmatchedDiscountText: {
      color: '#c94946',
      fontWeight: 800,
    },
  },

  StepContainer: {
    root: {
      '& label span': {
        color: theme.palette.primary.dark,
      },

      '& h5': {
        color: theme.palette.primary.dark,
      },
    },

    container: {
      '& h4': {
        color: theme.palette.primary.dark,
      },
    },

    stepCompleteIcon: {
      position: 'relative',
      backgroundColor: colors.successLight,
      width: '25px',
      height: '25px',
      top: '-4px',
    },

    completeContainer: {
      '& p': {
        fontFamily: secondaryFontFamily,
        fontWeight: theme.typography.fontWeightLight,
        fontSize: 14,
        textTransform: 'none',
      },
    },

    titleContainer: {
      '& button': {
        borderColor: theme.palette.primary.dark,
        color: theme.palette.primary.dark,
      },
    },
  },

  CreateUserStep: {
    passwordHint: {
      fontFamily: secondaryFontFamily,
    },
  },

  Checkout: {
    close: {
      '& svg': {
        fill: theme.palette.primary.dark,
      },
    },

    securePayment: {
      fontFamily: secondaryFontFamily,
      fontSize: 16,
      textTransform: 'none',
    },
  },

  CheckoutOrder: {
    root: {
      '& h4, p, span': {
        color: theme.palette.common.white,
      },

      '& button': {
        borderColor: theme.palette.common.white,
      },

      '& $value > span, $totalPrice': {
        textTransform: 'none',
        fontFamily: secondaryFontFamily,
        fontWeight: theme.typography.fontWeightLight,
      },
    },
  },

  CheckoutViewProducts: {
    root: {
      backgroundColor: '#efdecd',
    },

    titleContainer: {
      backgroundColor: '#efdecd',

      '& svg': {
        fill: theme.palette.primary.dark,
      },
    },
  },

  ShoppingCartContent: {
    close: {
      '& svg': {
        fill: theme.palette.primary.dark,
      },
    },
  },

  DeliveryMethod: {
    description: {
      '& p': {
        paddingLeft: '24px',
        textTransform: 'none',
        fontFamily: secondaryFontFamily,
        fontWeight: theme.typography.fontWeightLight,
        fontSize: 16,
      },
    },
    header: {
      '& p': {
        color: theme.palette.primary.dark,
      },
      '& span': {
        fontSize: '18px',
      },
    },
  },

  AdyenDropIn: {
    root: {
      '& .adyen-checkout__payment-method, label > span, .adyen-checkout__payment-method__header, .adyen-checkout__payment-method__name--selected':
        {
          fontWeight: theme.utils.createStyle(
            theme.typography.fontWeightBold,
            '!important',
          ),
          textTransform: 'uppercase',
        },

      '& .adyen-checkout__payment-method': {
        fontSize: theme.utils.getFontSize(18),
      },

      '& span.adyen-checkout__payment-method__name, label': {
        color: theme.palette.primary.dark,
      },

      '& label > span': {
        fontSize: theme.utils.getFontSize(14),
        lineHeight: theme.utils.getSize(14, { forceUnit: true }),
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },

  FieldsCheckbox: {
    label: {
      color: `${theme.palette.text.primary} !important`,
      textTransform: 'none',
      fontFamily: secondaryFontFamily,
      fontWeight: theme.typography.fontWeightLight,
      fontSize: 15,

      '& a': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },

  FieldsInput: {
    input: {
      fontFamily: secondaryFontFamily,
    },

    root: {
      '& label span sup': {
        transform: 'translate(4px, 6px)',
        display: 'inline-block',
      },
    },
  },

  FieldsRadio: {
    option: {
      padding: '15px 20px',
      '& label': {
        fontSize: 18,
        color: theme.palette.primary.dark,
      },
    },
  },

  FieldsSelect: {
    input: {
      backgroundColor: '#ECECEC',
      color: '#646464',
      borderRadius: 6,
      border: 'none',
      fontFamily: secondaryFontFamily,
      fontTransform: 'none',
    },
    inputWrap: {
      backgroundColor: '#ECECEC',
      borderRadius: 6,
      paddingRight: 6,
    },
  },

  FieldsAutoComplete: {
    input: {
      fontFamily: secondaryFontFamily,
    },

    listItem: {
      fontFamily: secondaryFontFamily,
      textTransform: 'none',
    },
  },

  OrderDeeplinkCompleted: {
    root: {
      '& p': {
        fontFamily: secondaryFontFamily,
        fontWeight: theme.typography.fontWeightMedium,
        textTransform: 'none',
      },
    },

    close: {
      '& svg': {
        fill: theme.palette.primary.dark,
      },
    },
  },

  OrderHistoryList: {
    bodyFont: {
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.utils.getSize(14),
      lineHeight: theme.utils.getSize(18, { forceUnit: true }),
      textTransform: 'none',
      fontFamily: secondaryFontFamily,
    },

    order: {
      borderRadius: 12,
    },
  },

  OrderHistoryDetail: {
    root: {
      borderRadius: 12,
    },

    bodyFont: {
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.utils.getSize(14),
      lineHeight: theme.utils.getSize(18, { forceUnit: true }),
      textTransform: 'none',
      fontFamily: secondaryFontFamily,
    },

    summaryTitle: {
      fontWeight: theme.typography.fontWeightRegular,
    },

    discountText: {
      fontWeight: theme.typography.fontWeightRegular,
    },

    totalPrice: {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },

  Alert: {
    text: {
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.utils.getSize(14),
      lineHeight: theme.utils.getSize(20, { forceUnit: true }),
      textTransform: 'none',
      fontFamily: secondaryFontFamily,
    },
  },
  AddToCart: {
    root: {
      [theme.breakpoints.down('sm')]: {
        alignItems: 'start',
      },
    },
    notifyMeOutOfStock: {
      color: '#4C4C4C',
      textTransform: 'none',
      fontFamily: secondaryFontFamily,
      fontWeight: 300,
    },
  },

  NotifyMeModal: {
    title: {
      color: colors.milupaGreen,
    },
    notificationMessage: {
      textTransform: 'none',
      fontFamily: secondaryFontFamily,
      fontWeight: 300,
    },
    message: {
      textTransform: 'none',
      fontFamily: secondaryFontFamily,
      fontWeight: 300,
    },
    notification: {
      background: '#F0F7E7',
    },
    checkIcon: {
      background: '#66B800',
    },
  },
  Modal: {
    closeIcon: {
      fill: '#4c4c4c',
    },
  },

  WidgetReviews: {
    beFirst: {
      fontFamily: secondaryFontFamily,
      fontWeight: 300,
      textTransform: 'none',
    },

    sorting: {
      '& .reviews-sorting': {
        '&__control': {
          borderRadius: '6px',
          backgroundColor: '#ECECEC',
          borderColor: '#DDDDDD',
        },

        '&__placeholder, &__single-value, &__option': {
          color: '#646464',
          fontFamily: secondaryFontFamily,
          fontWeight: 300,
        },

        '&__indicator > svg[width]': {
          fill: '#646464',
        },
      },
    },
  },

  Review: {
    root: {
      backgroundColor: 'white',
    },
    meta: {
      fontFamily: secondaryFontFamily,
      fontWeight: 300,
      textTransform: 'none',
    },
    content: {
      fontFamily: secondaryFontFamily,
      fontWeight: 300,
      textTransform: 'none',
    },

    action: {
      backgroundColor: theme.palette.primary.dark,
      color: 'white',

      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: 'white',
      },
    },
    careLineSubject: {
      color: 'white',
    },
    careLineContent: {
      backgroundColor: theme.palette.primary.dark,
    },
    careLineMeta: {
      color: 'white',
    },
    careLineComment: {
      fontFamily: secondaryFontFamily,
      fontWeight: 300,
      textTransform: 'none',
      color: 'white',
    },
  },

  CreateReview: {
    root: {
      [theme.breakpoints.up('md')]: {
        backgroundColor: 'white',
      },
    },
    productWrap: {
      backgroundColor: '#FFF0E5',
    },
    subtitle: {
      fontFamily: secondaryFontFamily,
      fontWeight: 300,
      textTransform: 'none',
    },
    productName: {
      color: theme.palette.primary.dark,
      fontWeight: 600,
    },
    productLink: {
      fontFamily: secondaryFontFamily,
      fontWeight: 300,
      textTransform: 'none',
    },
    hr: {
      borderColor: '#4C4C4C',
    },
  },
}

if (process.env.NX_TASK_TARGET_PROJECT === 'sites-example') {
  theme.exampleSiteStyles = {
    '@global': {
      body: {
        backgroundImage:
          'url(https://www.milupa.de/etc/designs/danone-eln/eln-dach-milupa/clientlib-base/images/pattern.png)',
        backgroundPosition: '50%',
        backgroundSize: 200,
        backgroundRepeat: 'repeat',
      },
    },
  }
}

export { theme }
